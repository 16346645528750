<template>
  <div class="fast_reply">
    <search-input class="search" @search="getList"/>
    <group class='mt40 mb30' @changeGroup="getData"/>
    
    <van-skeleton :row="3" :loading="loading">
      <div v-if="dataList&&dataList.length" class="message_list">
        <div v-for="item in dataList" :key="item.id" class="message_item" @click="sendMsg(item.content)" v-html="formatText(item.content)"></div>
      </div>
      <van-empty v-else description="无匹配内容" />
    </van-skeleton>
  </div>  
</template>
<script>
import { Skeleton,Empty } from 'vant'
import {getMessage} from '@/api/fastReply'
import searchInput from '@/components/searchInput'
import group from './group'
import { reactive,toRefs } from 'vue'
import {sendChatMessage} from '@/utils/weChatConfig'
// import {copy} from '@/utils/comOperTools'
import useClipboard from "vue-clipboard3"
import notify from '@/vant/notify'
import {formatTextArea} from '@/utils/commUtil'
export default {
  name:'FastReply',
  components:{
    [Skeleton.name]:Skeleton,
    [Empty.name]:Empty,
    searchInput,
    group
  },
   setup() {
    const { toClipboard } = useClipboard();
    const state=reactive({
      groupId:null,
      loading:false,
      dataList:[]
    })
    const formatText=(content)=>{
      return formatTextArea(content)
    }
    const getData=(groupId)=>{
      state.groupId=groupId
      getList()
    }
      
    const getList=(keyword)=>{
      const params={
        type: 1,
        keyword,
        groupId: state.groupId
      }
      state.loading=true
      getMessage(params).then(res => {
        state.loading=false
        if (res&&res.body){
          state.dataList=res.body.map(item=>{
            return {
              ...item
            }
          })
        } else {
          state.dataList=[]
        }
      })
    }
    const sendMsg=(content)=>{
      copyMsg(content)
      sendChatMessage('text',content)
    }
    const copyMsg=async (copyText)=>{
      if (!copyText) return
      await toClipboard(copyText)
      notify.success('复制成功')
    }
    return {
      ...toRefs(state),
      getData,
      getList,
      sendMsg,
      copyMsg,
      formatText
    }
   }
}
</script>
<style lang="less" scoped>
  .fast_reply{
    padding: 8px 32px;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    // background-color: #fff;
    .message_list{
      max-height: calc(~"100% - 250px");
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .message_item{
        background: #fafafa;
        border-radius: 16px;
        padding: 24px;
        line-height: 42px;
        font-size: 26px;
        white-space: pre-line;
        &:not(:last-of-type){
          margin-bottom: 20px;
        }
      }
    }
  }
</style>